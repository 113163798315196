import React from 'react';

// Translations
import { injectIntl } from 'react-intl';

const Eb2 = ({ intl }) => {
  const eb1ChartFull = intl.formatMessage({ id: 'img.eb-2-3_chart' });
  const eb1ChartThumb = intl.formatMessage({
    id: 'img.eb-2-3_chart.thumbnail',
  });

  return (
    <a
      href={require('assets/img/' + eb1ChartFull)}
      data-fancybox
      data-caption="EB-2 / EB-3 Petition Procedure"
    >
      <img
        src={require('assets/img/' + eb1ChartThumb)}
        style={{ width: '100%' }}
        alt=""
      />
    </a>
  );
};
export default injectIntl(Eb2);
