import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import Flight from '@material-ui/icons/Flight';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import Group from '@material-ui/icons/Group';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';

import classNames from 'classnames';

import { Link } from 'react-router-dom';

import imagesStyle from 'assets/jss/material-kit-react/imagesStyles.js';

import readBook from 'assets/img/read_book_sm.jpeg';
import turnaround from 'assets/img/turnaround_sm.jpeg';
import personalService from 'assets/img/personal_service_sm.jpeg';

import styles from 'assets/jss/material-kit-react/views/landingPageSections/productStyle.js';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

const useStyles = makeStyles(styles);
const imgStyles = makeStyles(imagesStyle);

// eslint-disable-next-line react/prop-types
const ProductSection = ({ intl }) => {
  const classes = useStyles();

  const imgClasses = imgStyles();

  const imageClasses = classNames(
    imgClasses.imgRaised,
    imgClasses.imgFluid,
    imgClasses.imgRounded,
  );

  const defaultFont = classNames(classes.fontSizeNormal, classes.description);

  return (
    <div className={classes.section}>
      <GridContainer justify="center" style={{ marginBottom: '60px' }}>
        <GridItem xs={12}>
          <div className={defaultFont} style={{ marginBottom: '50px' }}>
            <h2 className={classes.serifTitle}>
              <FormattedHTMLMessage id="label.your-case-matters" />
            </h2>

            <FormattedHTMLMessage id="landing-page.product.slogan" />
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card plain>
            <h4 className={classes.featureTitle}>
              <FormattedHTMLMessage id="personalized-service.title" />
            </h4>
            <img src={personalService} alt="" className={imageClasses} />
            <CardBody>
              <p className={classes.productFeatures}>
                <FormattedHTMLMessage id="personalized-service.description" />
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Card plain>
            <h4 className={classes.featureTitle}>
              <FormattedHTMLMessage id="turnaround-time.title" />
            </h4>
            <img src={turnaround} alt="" className={imageClasses} />
            <CardBody>
              <p className={classes.productFeatures}>
                <FormattedHTMLMessage id="turnaround-time.description" />
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Card plain>
            <h4 className={classes.featureTitle}>
              <FormattedHTMLMessage id="legal-trends.title" />
            </h4>
            <img src={readBook} alt="" className={imageClasses} />
            <CardBody>
              <p className={classes.productFeatures}>
                <FormattedHTMLMessage id="legal-trends.description" />
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <div className={defaultFont} style={{ marginBottom: '20px' }}>
              <h3 className={classes.serifTitle}>
                <FormattedHTMLMessage id="label.our-practices" />
              </h3>
            </div>
          </GridItem>

          <GridItem xs={12} sm={12} md={4} className={classes.raised}>
            <Link to="/practices/employment-based-visas">
              <InfoArea
                title={intl.formatMessage({
                  id: 'label.employment-based-immigration',
                })}
                description={intl.formatMessage({
                  id:
                    'product-section.employment-based-immigration.description',
                })}
                icon={BusinessCenter}
                iconColor="info"
                vertical
              />
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} className={classes.raised}>
            <Link to="/practices/family-based-visas">
              <InfoArea
                title={intl.formatMessage({
                  id: 'label.family-based-immigration',
                })}
                description={intl.formatMessage({
                  id: 'product-section.family-based-immigration.description',
                })}
                icon={Group}
                iconColor="danger"
                vertical
              />
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} className={classes.raised}>
            <Link to="/practices/non-immigrant-visas">
              <InfoArea
                title={intl.formatMessage({
                  id: 'label.non-immigrant-visas',
                })}
                description={intl.formatMessage({
                  id: 'product-section.non-immigrant-visas.description',
                })}
                icon={Flight}
                iconColor="success"
                vertical
              />
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default injectIntl(ProductSection);
