import React from 'react';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

// nodejs library that concatenates classes
import classNames from 'classnames';
import { FormattedHTMLMessage } from 'react-intl';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

// @material-ui/icons

// core components
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

function FirmComponent() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center" style={{ paddingBottom: '30px' }}>
        <GridItem xs={8} sm={8} md={6}>
          <h2>
            <FormattedHTMLMessage id="label.firm" />
          </h2>
          <FormattedHTMLMessage id="firm.summary" />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default function FirmPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  let match = useRouteMatch();
  return (
    <div>
      <Header
        color="dark"
        routes={dashboardRoutes}
        brand="Law Office of Hongfei Gao"
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />

      <div className={classNames(classes.main, classes.mainContent)}>
        <Switch>
          <Route path={`${match.url}`} component={FirmComponent}></Route>
        </Switch>
      </div>
      <Footer />
    </div>
  );
}
