import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

// Translations
import { FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';

import Eb1 from './Eb1';
import Eb2 from './Eb2';
import Eb3 from './Eb3';

const useStyles = makeStyles(styles);

function Summary(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer
        justify="center"
        style={{ paddingBottom: '30px', marginLeft: '0px', marginRight: '0px' }}
      >
        <GridItem xs={10} sm={8} md={8}>
          <h2>
            <FormattedHTMLMessage id="label.employment-based-immigration" />
          </h2>
        </GridItem>

        <GridItem xs={10} sm={8} md={8} style={{ marginBottom: '100px' }}>
          <GridContainer alignItems="center">
            <GridItem xs={12} sm={12} md={8}>
              <h3>
                <strong>
                  <FormattedHTMLMessage id="label.eb-1" />
                </strong>
              </h3>
              <FormattedHTMLMessage id="eb-1.summary" />
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <Eb1 />
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={10} sm={8} md={8} style={{ marginBottom: '50px' }}>
          <GridContainer alignItems="center">
            <GridItem xs={12} sm={12} md={8}>
              <h3>
                <strong>
                  <FormattedHTMLMessage id="label.eb-2" />
                </strong>
              </h3>
              <FormattedHTMLMessage id="eb-2.summary" />
              <h3>
                <strong>
                  <FormattedHTMLMessage id="label.eb-3" />
                </strong>
              </h3>
              <FormattedHTMLMessage id="eb-3.summary" />
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <Eb2 />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default function EmploymentBasedVisas(props) {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${match.url}/eb1`} component={Eb1}></Route>
        <Route path={`${match.url}/eb2`} component={Eb2}></Route>
        <Route path={`${match.url}/eb3`} component={Eb3}></Route>
        <Route path={`${match.url}`} component={Summary}></Route>
      </Switch>
    </div>
  );
}
