import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import List from "@material-ui/icons/List";

// Translations
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

const useStyles = makeStyles(styles);

const employmentBasedVisasTab = () => {
  return {
    tabButton: <FormattedMessage id="label.employment-based-immigration" />,
    tabIcon: Dashboard,
    tabContent: (
      <FormattedHTMLMessage id="practices.employment-based-immigration.description" />
    )
  };
};

const familyBasedVisasTab = () => {
  return {
    tabButton: <FormattedMessage id="label.family-based-immigration" />,
    tabIcon: Schedule,
    tabContent: (
      <FormattedHTMLMessage id="practices.family-based-immigration.description" />
    )
  };
};

const nonImmigrantTab = () => {
  return {
    tabButton: <FormattedMessage id="label.non-immigrant-visas" />,
    tabIcon: List,
    tabContent: (
      <FormattedHTMLMessage id="practices.non-immigrant-visas.description" />
    )
  };
};

const corporateKits = () => {
  return {
    tabButton: <FormattedMessage id="label.corporate-kits" />,
    tabIcon: List,
    tabContent: (
      <FormattedHTMLMessage id="practices.corporate-kits.description" />
    )
  };
};

export default function PracticesLandingPage(props) {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
          <GridContainer justify="center">
            <GridItem xs={12} sm={9} md={9} lg={8}>
              <h2>
                <FormattedMessage id="label.practices" />
              </h2>
              <NavPills
                color="primary"
                alignCenter={true}
                tabs={[
                  employmentBasedVisasTab(classes),
                  familyBasedVisasTab(),
                  nonImmigrantTab(),
                  corporateKits()
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
