import React from "react";
import { IntlContext } from "./IntlContext";
import Button from "components/CustomButtons/Button.js";

const LanguageSwitch = () => {
  const { switchToEnglish, switchToChinese } = React.useContext(IntlContext);
  return (
    <>
      <Button
        color="transparent"
        onClick={switchToEnglish}
        style={{
          margin: "12px 0px",
          padding: "5px 6px",
          borderRadius: "0",
          borderRight: "1px solid #ccc"
        }}
      >
        English
      </Button>
      <Button
        color="transparent"
        onClick={switchToChinese}
        style={{
          margin: "12px 0px",
          padding: "5px 5px"
        }}
      >
        中文
      </Button>
    </>
  );
};

export default LanguageSwitch;
