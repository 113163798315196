import React, { Component } from 'react';
import { IntlProviderWrapper } from './languages/IntlContext';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import 'assets/scss/material-kit-react.scss?v=1.8.0';
import ScrollToTop from './views/ScrollToTop.js';

import LandingPage from './views/LandingPage/LandingPage';
import ProfilePage from './views/ProfilePage/ProfilePage';
import LoginPage from './views/LoginPage/LoginPage';
import PracticesPage from './views/PracticesPage/PracticesPage';
import FirmPage from './views/FirmPage/FirmPage';
import Components from './views/Components/Components';

var hist = createBrowserHistory();

function App() {
  return (
    <IntlProviderWrapper>
      <Router history={hist}>
        <ScrollToTop />
        <Switch>
          <Route path="/components" component={Components} />
          <Route path="/profile-page" component={ProfilePage} />
          <Route path="/login-page" component={LoginPage} />
          <Route path="/practices" component={PracticesPage} />
          <Route path="/firm" component={FirmPage} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </Router>
    </IntlProviderWrapper>
  );
}

export default App;
