import React from 'react';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// Translations
import { makeStyles } from '@material-ui/core';

import { injectIntl } from 'react-intl';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';

const useStyles = makeStyles(styles);

const Eb3 = ({ intl }) => {
  const classes = useStyles();
  const source = intl.formatMessage({ id: 'img.eb-2-3_chart' });
  return (
    <div className={classes.section}>
      <GridContainer justify="center" style={{ paddingBottom: '30px' }}>
        <GridItem xs={12} sm={8} md={6}>
          <img
            src={require('assets/img/' + source)}
            style={{ width: '100%' }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default injectIntl(Eb3);
