import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import { Route, Switch } from 'react-router-dom';

// @material-ui/icons

// core components
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';

import EmploymentBasedVisas from './Practices/EmploymentBasedVisas';
import FamilyBasedVisas from './Practices/FamilyBasedVisas';
import NonImmigrantVisas from './Practices/NonImmigrantVisas';
import CorporateKits from './Practices/CorporateKits';
import PracticesLandingPage from './Practices/PracticesLandingPage';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function PracticesPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        color="dark"
        routes={dashboardRoutes}
        brand="Hi"
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />
      <div className={classNames(classes.main, classes.mainContent)}>
        <Switch>
          <Route
            path="/practices/employment-based-visas"
            component={EmploymentBasedVisas}
          />
          <Route
            path="/practices/family-based-visas"
            component={FamilyBasedVisas}
          />
          <Route
            path="/practices/non-immigrant-visas"
            component={NonImmigrantVisas}
          />
          <Route path="/practices/corporate-kits" component={CorporateKits} />
          <Route path="/practices" component={PracticesLandingPage} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}
