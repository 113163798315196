import { title } from 'assets/jss/material-kit-react.js';

const productStyle = {
  raised: {
    borderRadius: '10px',
    boxShadow: '0 2px 4px 1px rgba(0, 0, 0, 0.15)',
    cursor: 'pointer',
    '&:hover,&:focus': {
      boxShadow:
        '0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)',
      willChange: 'box-shadow, transform',
      transition:
        'box-shadow 0.4s cubic-bezier(0.4, 0, 1, 1), background-color 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
    },
  },
  section: {
    padding: '70px 0',
    textAlign: 'center',
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  description: {
    color: '#888',
  },
  productFeatures: {
    color: '#888',
    textAlign: 'left',
  },
  serifTitle: {
    fontFamily: 'Libre Baskerville',
    color: '#333',
  },
  featureTitle: {
    fontFamily: 'Libre Baskerville',
    color: '#555',
    fontSize: '1.5em',
  },
  fontSizeNormal: {
    fontSize: '16px',
  },
};

export default productStyle;
