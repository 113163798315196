import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

// Translations
import { FormattedHTMLMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';

const useStyles = makeStyles(styles);

function Summary(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center" style={{ paddingBottom: '30px' }}>
        <GridItem xs={8} sm={8} md={6}>
          <h2>
            <FormattedHTMLMessage id="label.family-based-immigration" />
          </h2>
          <FormattedHTMLMessage id="family-based-visa.summary" />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default function FamilyBasedVisas(props) {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${match.url}`} component={Summary}></Route>
      </Switch>
    </div>
  );
}
