import React from "react";

import { IntlProvider } from "react-intl";

import messages_zh from "assets/translations/zh.json";
import messages_en from "assets/translations/en.json";

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);

    const supportedLanguages = ["en", "zh"];
    const messagesLanguage = {
      en: messages_en,
      zh: messages_zh
    }

    this.switchToEnglish = () =>
      this.setState({ locale: "en", messages: messagesLanguage["en"] });

    this.switchToChinese = () =>
      this.setState({ locale: "zh", messages: messagesLanguage["zh"] });

    const browserLocale = navigator.language.split("-")[0];
    const localeSupported = supportedLanguages.includes(browserLocale);
    const locale = localeSupported ? browserLocale : "en";

    // pass everything in state to avoid creating object inside render method (like explained in the documentation)
    this.state = {
      locale: locale,
      messages: messagesLanguage[locale],
      switchToEnglish: this.switchToEnglish,
      switchToChinese: this.switchToChinese
    };
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };
