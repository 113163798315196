import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';

// Translations
import { FormattedHTMLMessage } from 'react-intl';

import styles from 'assets/jss/material-kit-react/views/landingPageSections/teamStyle.js';

import frontFacing from 'assets/img/faces/front-sq.jpg';

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid,
  );
  return (
    <div className={classes.section}>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card plain>
              <h3 className={classes.cardTitle}>
                <FormattedHTMLMessage id="label.hongfei-gao" />
                <br />
                <small className={classes.smallTitle}>
                  <FormattedHTMLMessage id="label.attorney" />
                </small>
              </h3>
              <GridItem xs={12} sm={12} md={5} className={classes.itemGrid}>
                <img
                  src={frontFacing}
                  alt="Attorney"
                  className={imageClasses}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <CardBody>
                  <p className={classes.description}>
                    <FormattedHTMLMessage id="team-section.hongfei-gao.description" />
                  </p>
                </CardBody>
              </GridItem>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
