import React from 'react';

// Translations
import { injectIntl } from 'react-intl';

const Eb1 = ({ intl }) => {
  const eb1ChartFull = intl.formatMessage({ id: 'img.eb-1_chart' });
  const eb1ChartThumb = intl.formatMessage({ id: 'img.eb-1_chart.thumbnail' });

  return (
    <a
      href={require('assets/img/' + eb1ChartFull)}
      data-fancybox
      data-caption="EB-1 Petition Procedure"
    >
      <img
        src={require('assets/img/' + eb1ChartThumb)}
        style={{ width: '100%' }}
        alt=""
      />
    </a>
  );
};
export default injectIntl(Eb1);
