/*eslint-disable*/
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import { Apps, CloudDownload } from '@material-ui/icons';

// core components
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Button from 'components/CustomButtons/Button.js';

// Translations
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import LanguageSwitch from 'languages/LanguageSwitch';

import styles from 'assets/jss/material-kit-react/components/headerLinksStyle.js';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link to="/firm" style={{ color: 'inherit' }}>
          <Button color={'transparent'}>
            <Icon className="business">business</Icon>
            <FormattedHTMLMessage id="label.firm" />
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Practices"
          buttonProps={{
            className: classes.navLink,
            color: 'transparent',
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link
              to="/practices/employment-based-visas"
              className={classes.dropdownLink}
            >
              <FormattedHTMLMessage id="label.employment-based-immigration" />
            </Link>,
            <Link
              to="/practices/family-based-visas"
              className={classes.dropdownLink}
            >
              <FormattedHTMLMessage id="label.family-based-immigration" />
            </Link>,
            <Link
              to="/practices/non-immigrant-visas"
              className={classes.dropdownLink}
            >
              <FormattedHTMLMessage id="label.non-immigrant-visas" />
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/" style={{ color: 'inherit' }}>
          <Button
            color={'transparent'}
            style={{ margin: '.3125rem 10px .3125rem 1px' }}
          >
            <Icon className="contact_support">contact_support</Icon>
            <FormattedHTMLMessage id="label.contact" />
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <LanguageSwitch />
      </ListItem>
    </List>
  );
}
