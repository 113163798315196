import React, { useState } from 'react';

import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';

import styles from 'assets/jss/material-kit-react/views/landingPageSections/workStyle.js';

// Translations
import { injectIntl, FormattedMessage } from 'react-intl';
import Check from '@material-ui/icons/Check';

const useStyles = makeStyles(styles);

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: event => {
        setValue(event.target.value);
      },
    },
  };
};

const ContactForm = ({ intl }) => {
  const classes = useStyles();

  const [formResponse, setFormResponse] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const {
    value: firstName,
    bind: bindFirstName,
    reset: resetFirstName,
  } = useInput('');
  const {
    value: lastName,
    bind: bindLastName,
    reset: resetLastName,
  } = useInput('');
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput('');
  const { value: phone, bind: bindPhone, reset: resetPhone } = useInput('');
  const { value: message, bind: bindMessage, reset: resetMessage } = useInput(
    '',
  );

  const errorMsg = (
    <SnackbarContent
      message={
        <FormattedMessage id="landing-page.contact-form.form-response.error" />
      }
      color="danger"
      icon="info_outline"
    />
  );

  const successMsg = (
    <SnackbarContent
      message={
        <FormattedMessage id="landing-page.contact-form.form-response.success" />
      }
      color="success"
      icon={Check}
    />
  );

  const handleSubmit = event => {
    event.preventDefault();

    let formErrs = false;

    let updateErrs = {
      firstName: false,
      lastName: false,
      email: false,
      message: false,
    };

    if (firstName.trim() === '') {
      updateErrs.firstName = true;
      formErrs = true;
    } else {
      updateErrs.firstName = false;
    }

    if (lastName.trim() === '') {
      updateErrs.lastName = true;
      formErrs = true;
    } else {
      updateErrs.lastName = false;
    }

    if (!emailRegex.test(email)) {
      updateErrs.email = true;
      formErrs = true;
    } else {
      updateErrs.email = false;
    }

    if (message.trim() === '') {
      updateErrs.message = true;
      formErrs = true;
    } else {
      updateErrs.message = false;
    }

    setFormErrors(updateErrs);

    if (formErrs) {
      return;
    }

    const headers = {
      'Content-Type': 'application/json',
    };

    axios
      .post(
        process.env.REACT_APP_API_URL + '/contact-form',
        //   .post("127.0.0.1", {
        {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          message: message,
        },
        {
          headers: headers,
        },
      )
      .then(function(response) {
        setFormResponse(successMsg);
      })
      .catch(function(error) {
        setFormResponse(errorMsg);
      });

    resetFirstName();
    resetLastName();
    resetEmail();
    resetPhone();
    resetMessage();
  };

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>
            <a id="anchor-contact">
              <FormattedMessage id="landing-page.contact-form.title" />
            </a>
          </h2>
          <h4 className={classes.description}>
            <FormattedMessage id="landing-page.contact-form.description" />
          </h4>
          <form>
            <GridContainer justify="center">
              <GridItem xs={10} sm={10} md={6}>
                {formErrors.firstName ? (
                  <CustomInput
                    labelText={intl.formatMessage({
                      id: 'label.first-name.invalid',
                    })}
                    id="firstName"
                    error
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={bindFirstName}
                  />
                ) : (
                  <CustomInput
                    labelText={intl.formatMessage({
                      id: 'label.first-name',
                    })}
                    id="firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={bindFirstName}
                  />
                )}
              </GridItem>
              <GridItem xs={10} sm={10} md={6}>
                {formErrors.lastName ? (
                  <CustomInput
                    labelText={intl.formatMessage({
                      id: 'label.last-name.invalid',
                    })}
                    id="lastName"
                    error
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={bindLastName}
                  />
                ) : (
                  <CustomInput
                    labelText={intl.formatMessage({
                      id: 'label.last-name',
                    })}
                    id="lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={bindLastName}
                  />
                )}
              </GridItem>
              <GridItem xs={10} sm={10} md={6}>
                {formErrors.email ? (
                  <CustomInput
                    labelText={intl.formatMessage({
                      id: 'label.email.invalid',
                    })}
                    id="email"
                    error
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={bindEmail}
                  />
                ) : (
                  <CustomInput
                    labelText={intl.formatMessage({
                      id: 'label.email',
                    })}
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={bindEmail}
                  />
                )}
              </GridItem>
              <GridItem xs={10} sm={10} md={6}>
                <CustomInput
                  labelText={intl.formatMessage({
                    id: 'label.phone',
                  })}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={bindPhone}
                />
              </GridItem>
              <GridItem xs={10} sm={10} md={12}>
                {formErrors.message ? (
                  <CustomInput
                    labelText={intl.formatMessage({
                      id: 'label.your-message.invalid',
                    })}
                    id="message"
                    error
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      ...bindMessage,
                    }}
                  />
                ) : (
                  <CustomInput
                    labelText={intl.formatMessage({
                      id: 'label.your-message',
                    })}
                    id="message"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      ...bindMessage,
                    }}
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={4} className={classes.textCenter}>
                <Button color="primary" onClick={handleSubmit}>
                  <FormattedMessage id="label.submit" />
                </Button>
              </GridItem>
            </GridContainer>
          </form>
          {formResponse}
        </GridItem>
      </GridContainer>
    </div>
  );
};

ContactForm.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(ContactForm);
